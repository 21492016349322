<template>
	<div style="background: white; padding: 10px 20px;">
		<el-form :model="formData" :rules="rules" ref="form">
			<el-form-item label="显示名称" prop="nickname">
				<el-input
					placeholder="请输入显示名称"
					v-model="formData.nickname"
					:maxlength="30"
				></el-input>
			</el-form-item>
			<el-form-item label="手机号" prop="phoneNumber">
				<el-input
					placeholder="请输入手机号"
					v-model="formData.phoneNumber"
					:maxlength="11"
				></el-input>
			</el-form-item>
			<el-form-item label="密码" prop="password">
				<el-input
					placeholder="请输入密码"
					v-model="formData.password"
					:maxlength="30"
				></el-input>
			</el-form-item>

			
			<el-form-item label="权限设置" prop="access">
				<br/>
				<manager-access-editor v-model="formData.access"/>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="onSubmit">创建</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import { Form, FormItem, Input, Button, Message } from "element-ui";
import ManagerAccessEditor from '../../components/ManagerAccessEditor.vue';
export default {
	name: "CreateManager",
	components: {
		ElForm: Form,
		ElFormItem: FormItem,
		ElInput: Input,
		ElButton: Button,
		ManagerAccessEditor,
	},
	data(){
		return {
			formData: {
				nickname: '',
				phoneNumber: '',
				access: 0,
				password: '',
			},
			rules: {
				nickname: [
					{ required: true, message: "请输入显示名称", trigger: "blur" },
					{
						min: 2,
						max: 20,
						message: "长度在 2 到 20 个字符",
						trigger: "blur",
					},
				],
				phoneNumber: [
					{ required: true, message: "请输入手机号码", trigger: "blur" },
					{
						pattern: /^1[0-9]{10}$/,
						message: "请输入正确的手机号码",
						trigger: "blur",
					},
				],
				password: [
					{ required: true, message: "请输入密码", trigger: "blur" },
					{
						min: 8,
						max: 40,
						message: "长度在 8 到 40 个字符",
						trigger: "blur",
					},
				]
			}
		}
	},
	methods: {
		async onSubmit(){

			this.$refs.form.validate(async (valid) => {
				if (!valid) {
					Message.error("请输入完整正确后再提交哦！");
					return false;
				}

				try{
					const {data} = await this.$api.manager.manager.create(this.formData.phoneNumber, this.formData.password, this.formData.access, this.formData.nickname)
					if (data.code === "OK") {
						Message.success('创建管理员 '+this.formData.nickname+ ' 成功！')
						this.$router.push({ name: "ManagerList" });
					} else {
						Message.error("错误：" + data.errmsg);
					}
				} catch(e){
					Message.error("错误：" + e);
				}

			})
			
		}
	}
};
</script>

<style>
</style>