<template>
	<div>
		<el-form label-width="160px">
			
			<el-form-item label="超级管理员">
				<el-switch
					:value="Boolean(access & ACCESS.Super)"
					disabled
				></el-switch>
			</el-form-item>
			<el-alert v-if="Boolean(access & ACCESS.Super)" :closable="false" type="info">超级管理员拥有全部权限, 以下权限设置对超级管理员无效</el-alert>
			<el-form-item label="审核订单：">
				<el-switch
					:value="Boolean(access & ACCESS.Checking)"
					@change="(v) => changeOne(v, ACCESS.Checking)"
					:disabled="disabled"
				></el-switch>
			</el-form-item>

			<el-form-item label="确认接收用户快递：">
				<el-switch
					:value="Boolean(access & ACCESS.UserPostReceived)"
					@change="(v) => changeOne(v, ACCESS.UserPostReceived)"
					:disabled="disabled"
				></el-switch>
			</el-form-item>

			<el-form-item label="确认开始处理订单：">
				<el-switch
					:value="Boolean(access & ACCESS.StartProcessing)"
					@change="(v) => changeOne(v, ACCESS.StartProcessing)"
					:disabled="disabled"
				></el-switch>
			</el-form-item>

			<el-form-item label="导板设计：">
				<el-switch
					:value="Boolean(access & ACCESS.GuidePlateDesign)"
					@change="(v) => changeOne(v, ACCESS.GuidePlateDesign)"
					:disabled="disabled"
				></el-switch>
			</el-form-item>

			<el-form-item label="导板制作：">
				<el-switch
					:value="Boolean(access & ACCESS.GuidePlateProduce)"
					@change="(v) => changeOne(v, ACCESS.GuidePlateProduce)"
					:disabled="disabled"
				></el-switch>
			</el-form-item>
			<el-form-item label="产品配货：">
				<el-switch
					:value="Boolean(access & ACCESS.ProductDistribution)"
					@change="(v) => changeOne(v, ACCESS.ProductDistribution)"
					:disabled="disabled"
				></el-switch>
			</el-form-item>
			<el-form-item label="产品发货：">
				<el-switch
					:value="Boolean(access & ACCESS.ProductPost)"
					@change="(v) => changeOne(v, ACCESS.ProductPost)"
					:disabled="disabled"
				></el-switch>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import { Switch, Form, FormItem, Alert } from "element-ui";

export default {
	name: "ManagerAccessEditor",
	props: {
		access: {
			type: Number,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		
	},
	model: {
		prop: "access",
		event: "change",
	},
	components: {
		ElSwitch: Switch,
		ElForm: Form,
		ElFormItem: FormItem,
		ElAlert: Alert,
	},
	data() {
		return {
			ACCESS: {
				Empty: 0b0,
				Super: 0b1,
				Checking: 0b10,
				UserPostReceived: 0b100,
				StartProcessing: 0b1000,
				GuidePlateDesign: 0b10000,
				GuidePlateProduce: 0b100000,
				ProductDistribution: 0b1000000,
				ProductPost: 0b10000000,
			},
		};
	},
	methods: {
		changeOne(open, flag) {
			let access = this.access;
			if (open) {
				access |= flag;
			} else {
				access ^= flag;
			}
			this.$emit("change", access);
		},
	},
};
</script>

<style>
</style>